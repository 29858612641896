export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27')
];

export const server_loads = [2];

export const dictionary = {
		"/[[lang=lang]]/blog": [~5,[2],[3]],
		"/[[lang=lang]]/blog/categories/[slug]/[[page=page]]/[[page=int]]": [~7,[2],[3]],
		"/[[lang=lang]]/blog/[slug]": [~6,[2],[3]],
		"/[[lang=lang]]/contact-us": [~8,[2],[3]],
		"/[[lang=lang]]/drugs": [~9,[2],[3]],
		"/[[lang=lang]]/drugs/conditions/[condition]": [~13,[2],[3]],
		"/[[lang=lang]]/drugs/letter/[letter]": [~14,[2],[3]],
		"/[[lang=lang]]/drugs/[slug]": [~10,[2],[3]],
		"/[[lang=lang]]/drugs/[slug]/images": [~11,[2],[3]],
		"/[[lang=lang]]/drugs/[slug]/savings-card": [~12,[2],[3]],
		"/[[lang=lang]]/help": [~15,[2],[3]],
		"/[[lang=lang]]/help/articles/[slug]": [~17,[2],[3]],
		"/[[lang=lang]]/help/[slug]": [~16,[2],[3]],
		"/[[lang=lang]]/pets": [~18,[2],[3]],
		"/[[lang=lang]]/pharmacy-near-me/[[service=service]]/[stateabbreviation=state]/[[page=page]]/[[pagenumber=int]]": [~25,[2],[3]],
		"/[[lang=lang]]/pharmacy-near-me/[[service=service]]/[stateabbreviation=state]/[city]/[[page=page]]/[[pagenumber=int]]": [~26,[2],[3]],
		"/[[lang=lang]]/pharmacy-near-me/[[service=service]]": [~19,[2],[3]],
		"/[[lang=lang]]/pharmacy-near-me/[[service=service]]/[chain]": [~20,[2],[3]],
		"/[[lang=lang]]/pharmacy-near-me/[[service=service]]/[chain]/discount-card": [~24,[2],[3]],
		"/[[lang=lang]]/pharmacy-near-me/[[service=service]]/[chain]/[stateabbreviation=state]/[[page=page]]/[[pagenumber=int]]": [~21,[2],[3]],
		"/[[lang=lang]]/pharmacy-near-me/[[service=service]]/[chain]/[stateabbreviation=state]/[city]/[[page=page]]/[[pagenumber=int]]": [~22,[2],[3]],
		"/[[lang=lang]]/pharmacy-near-me/[[service=service]]/[chain]/[stateabbreviation=state]/[city]/[address]/[npi]/[[page=page]]/[[pagenumber=int]]": [~23,[2],[3]],
		"/[[lang=lang]]/savings-card": [~27,[2],[3]],
		"/[[lang=lang]]/[[slug]]": [~4,[2],[3]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';