// Test if param has 2 characters and is a state page
export function match(param) {
  if (/^[a-zA-Z]{2}$/.test(param)) {
    const stateAbbreviations = [
      'al',
      'ak',
      'az',
      'ar',
      'ca',
      'co',
      'ct',
      'dc',
      'de',
      'fl',
      'ga',
      'hi',
      'id',
      'il',
      'in',
      'ia',
      'ks',
      'ky',
      'la',
      'me',
      'md',
      'ma',
      'mi',
      'mn',
      'ms',
      'mo',
      'mt',
      'ne',
      'nv',
      'nh',
      'nj',
      'nm',
      'ny',
      'nc',
      'nd',
      'oh',
      'ok',
      'or',
      'pa',
      'pr',
      'ri',
      'sc',
      'sd',
      'tn',
      'tx',
      'ut',
      'va',
      'vi',
      'vt',
      'wa',
      'wv',
      'wi',
      'wy',
    ];
    return stateAbbreviations.includes(param);
  } else {
    return false;
  }
}
